.year{
  font-size: 30px;
}
.comp{
  font-size: 30px;
}
.desc{
  font-size: 26px;
}
.cv{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;

}
.left{
  width:30%;
  background: rgb(30,30,30);
  color:white;
  display:grid;
  flex-direction: column;

}
.right{
  width: 60%;
}
.admin{
  width: 10%;
}
.pho{
  width:250px;
  height: 300px;
  left: calc(20% - 50px);
  position: absolute;
  top: 5%;
}
.mn{

}
.contact{
  font-size: 45px;
  font-weight: bold;
  position: relative;
  padding-left: 20%;
  /*display: grid;*/
  /*align-items: end;*/
  padding-top: 200px;
}
.educ{
  font-size: 45px;
  font-weight: bold;
  padding-left: 20%;
  padding-top: 10%;
}
.action{
  padding-left: 15%;
  font-size: 30px;
}
.cmpn{
  padding-top: 30px;
}
.expi{
  padding-left: 20%;
  padding-top: 5%;
}
.expe{
  font-size: 45px;
  font-weight: bold;

}
.contactp{
  padding-left: 25%;
}
.educP{
 font-weight: normal;
  padding-top: 5%;
  padding-left: 5%;
}
.ehd{

  font-size: 26px;
}
.eid{
  font-size: 30px;
  font-weight:bold;
}
.eps{
  font-size: 26px;
}
.lb{
  background: darkred;
  width: 80%;
  height: 200px;
}
.posi{
  /*display: flex;*/
}
.position{
  font-size: 25px;
  font-family: Arial;
  /*text-align: center;*/
  padding-left: 150px;
  padding-top: 25px;
  color: darkred;
  font-weight: bold;
}
.FIO{
  display: flex;
  flex-direction: row;
  font-family: Arial;
  font-size: 40px;
  padding-top: 20px;

}
.hd{
  margin-top: 10px;
  font-size: 30px;
  font-weight: bold;
}
.id{
  font-size: 25px;
}
.Name{
  padding-left: 120px;
  font-weight: bold;
  /*text-align: center;*/
}
.Last{
  padding-left: 10px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
